import styled from 'styled-components';

export const Wrapper = styled.div`
	padding-bottom: 4rem;
	background-size: contain;
	background-position: left;
	background-repeat: no-repeat;
	background-color: ${({ theme }) => (theme === 'light' ? '#fff' : '#121212)')};
	border-left: 30px solid #008037;
`;

export const Grid = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 8fr;
	gap: 1.2rem 1.2rem;

	@media (max-width: 960px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 680px) {
		grid-template-columns: 1fr;
	}
`;

export const Item = styled.div`
	width: 100%;
	height: 100%;

	h4 {
		color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
	}

	p {
		color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
	}
`;

export const Content = styled.div`
	padding: 1rem 0;
	min-height: 160px;
`;

export const Stats = styled.div`
	display: flex;
	align-items: center;

	div {
		display: flex;
		&:first-child {
			margin-right: 0.5rem;
		}

		img {
			margin: 0;
		}

		svg path {
			fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
		}

		span {
			color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
			margin-left: 0.5rem;
		}
	}
`;

export const Languages = styled.div`
	opacity: 0.5;
	font-size: 14px;
`;
